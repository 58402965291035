import backArrow from '../../assets/imgs/icons/back-arrow.svg'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

interface PageHeaderProps {
	title: string
	controls?: JSX.Element
	body?: JSX.Element
	footer?: JSX.Element
	backLink?: string
}

function PageHeader(props: PageHeaderProps) {
	return (
		<div className="page-info">
			<Container fluid={true}>
				<Row className="align-items-center" md="auto">
					<Col xs="auto">
						{props.backLink && (
							<Link to={props.backLink} className="btn-back">
								<img src={backArrow} alt="" /> Back
							</Link>
						)}
						<h1>{props.title}</h1>
					</Col>
					{props.controls}
				</Row>
				<Row>{props.body}</Row>
				<Row className="align-items-center">{props.footer}</Row>
			</Container>
		</div>
	)
}

export default PageHeader
