import * as process from 'process'

import iconAnnualFee from 'assets/imgs/icons/transactions/annual-fee.svg'
import iconATM from 'assets/imgs/icons/transactions/atm.svg'
import iconBalance from 'assets/imgs/icons/transactions/balance.svg'
import iconCreditTransfer from 'assets/imgs/icons/transactions/credit-transfer.svg'
import iconECommerce from 'assets/imgs/icons/transactions/ecommerce.svg'
import iconEMoneyRedeem from 'assets/imgs/icons/transactions/emoney-redeem.svg'
import iconFastCash from 'assets/imgs/icons/transactions/fast-cash.svg'
import iconFee from 'assets/imgs/icons/transactions/fee.svg'
import iconFXChange from 'assets/imgs/icons/transactions/fxchange.svg'
import iconManual from 'assets/imgs/icons/transactions/manual.svg'
import iconP2PTransfer from 'assets/imgs/icons/transactions/p2p-transfer.svg'
import iconPaperPin from 'assets/imgs/icons/transactions/paper-pin.svg'
import iconPersonalTransfer from 'assets/imgs/icons/transactions/personal-transfer.svg'
import iconPINChange from 'assets/imgs/icons/transactions/pin-change.svg'
import iconPinSelection from 'assets/imgs/icons/transactions/pin-selection.svg'
import iconPOSCash from 'assets/imgs/icons/transactions/pos-cash.svg'
import iconPOS from 'assets/imgs/icons/transactions/pos.svg'
import iconPreauthorization from 'assets/imgs/icons/transactions/preauthorization.svg'
import { TransactionType } from '../api/Models'

import _iconClose from 'assets/imgs/icons/close.svg'
import _iconSearch from 'assets/imgs/icons/selector-search.svg'

export const iconClose = _iconClose
export const iconSearch = _iconSearch

declare function require(name: string): any
type undefinedString = string | null | undefined

function get(brand: undefinedString, path: string) {
	return require(getBrandPath(brand, path)).default as string
}

function getBrandPath(brand: undefinedString, path: string) {
	return brand == undefined || brand === null || brand === '' ? `assets/${path}` : `assets/${brand}/${path}`
}

export const brand = process.env.REACT_APP_BRAND

export const iconArrowDown = () => get(brand, 'imgs/icons/select-arrow.svg')
export const iconInfo = () => get(brand, 'imgs/icons/transaction-info.svg')
export const iconChargeBack = () => get(brand, 'imgs/icons/transaction-chargeback.svg')
export const iconHourglass = () => get(brand, 'imgs/icons/hourglass.svg')
export const iconBriefcase = () => get(brand, 'imgs/icons/business.svg')
export const iconTag = () => get(brand, 'imgs/icons/type.svg')
export const iconCard = () => get(brand, 'imgs/icons/card.svg')

export function getTransactionIcon(type: TransactionType) {
	switch (type.toUpperCase()) {
		case 'ANNUALFEE':
		case 'ANNUAL_FEE':
			return iconAnnualFee
		case 'ATM':
			return iconATM
		case 'BALANCE':
			return iconBalance
		case 'CREDITTRANSFER':
			return iconCreditTransfer
		case 'ECOMM':
		case 'ECOMMERCE':
			return iconECommerce
		case 'EMONEY_REDEEM':
			return iconEMoneyRedeem
		case 'FASTCASH':
		case 'FAST_CASH':
			return iconFastCash
		case 'FEE':
			return iconFee
		case 'FXCHANGE':
			return iconFXChange
		case 'MANUAL':
			return iconManual
		case 'P2PTRANSFER':
			return iconP2PTransfer
		case 'PAPERPIN':
			return iconPaperPin
		case 'PERSONALTRANSFER':
			return iconPersonalTransfer
		case 'PINCHANGE':
		case 'PIN_CHANGE':
			return iconPINChange
		case 'PINSELECTION':
			return iconPinSelection
		case 'POSCASH':
			return iconPOSCash
		case 'POS':
			return iconPOS
		case 'PREAUTHORIZATION':
			return iconPreauthorization
		default:
			return iconInfo()
	}
}
