import { useState } from 'react'
import TabButton, { TabButtonProps } from './TabButton'

interface TabButtonsProps {
	buttons: TabButtonProps[]
	onTabChange?: (index: number) => void
	initialSelected?: number
}

function TabButtons(props: TabButtonsProps) {
	const [selected, setSelected] = useState(props.initialSelected ?? 0)

	const onClick = (index: number) => {
		if (index !== selected) {
			if (props.onTabChange) {
				props.onTabChange(index)
			}

			setSelected(index)
		}
	}

	return (
		<>
			{props.buttons.map((b: TabButtonProps, index: number) => {
				return <TabButton key={index} {...b} onClick={() => onClick(index)} active={selected === index} />
			})}
		</>
	)
}

export default TabButtons
