import creditCardPlus from 'assets/imgs/icons/credit-card-plus.svg'
import creditCardBan from 'assets/imgs/icons/credit-card-ban.svg'
import creditCardConvert from 'assets/imgs/icons/credit-card-convert.svg'
import iconRightArrow from 'assets/imgs/icons/link-arrow-right.svg'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Pagination } from 'swiper/modules'
import BasePage from 'components/pages/BasePage'
import CardView from 'components/elements/CardView'
import PageHeader from 'components/elements/PageHeader'
import LinkButton from 'components/elements/LinkButton'
import TransactionTable from 'components/elements/TransactionTable'
import TabButtons from 'components/elements/TabButtons'
import { TabButtonProps } from 'components/elements/TabButton'
import { AccountData, CardData } from 'api/Models'
import API from 'api/API'
import { formatCurrency } from 'utils'
import { BeatLoader, RiseLoader } from 'react-spinners'
import { Col, Row } from 'react-bootstrap'
import { cardManagementRoute, newCardRoute } from '../../Routes'
import IconButton from '../elements/IconButton'
import FreezeCardsModal from '../elements/modals/FreezeCardsModal'

function CardCarousel() {
	const settings = {
		spaceBetween: 30,
		slidesPerView: 'auto',
		freeMode: true,
		modules: [FreeMode, Pagination],
		className: 'carousel-card',
	}

	const [cards, setCards] = useState<CardData[] | null>(null)

	useEffect(() => {
		API.Cards.GetCardsWithBalance().then((result) => {
			if (result.success && result.data) {
				setCards(result.data.data)
			}
		})
	}, [])

	return (
		<div className="carousel-cards flickity-enabled is-draggable">
			{cards === null ? (
				<RiseLoader size={15} />
			) : (
				<Swiper {...settings} style={{ height: '200px' }}>
					{cards ? (
						cards?.map((c) => (
							<SwiperSlide key={c.id ?? Math.random()}>
								<CardView
									cardId={c.id ?? ''}
									cardDesign={c.cardDesign ?? 0}
									cardNumber={c.processorData?.obscuredPan ?? '****'}
									cardName={c.processorData?.embossName ?? ''}
									cardBalance={c.balance ?? 0}
									cardCurrency={c.currency}
									cardStatus={c.status ?? 'Unknown'}
									cardType={c.type}
								/>
							</SwiperSlide>
						))
					) : (
						<p>No cards available</p>
					)}
				</Swiper>
			)}
		</div>
	)
}

interface HeaderControlsProps {
	accountData?: AccountData | null
	selectedTab: number
	setSelectedTab: (i: number) => void
}

function HeaderControls(props: HeaderControlsProps) {
	const tabButtons: TabButtonProps[] = [{ label: 'Account balance' }, { label: 'All card balances' }]
	const [showFreezeCardsModal, setShowFreezeCardsModal] = useState(false)

	return (
		<>
			<Col className="me-auto">
				<TabButtons
					buttons={tabButtons}
					initialSelected={props.selectedTab}
					onTabChange={(i) => {
						props.setSelectedTab(i)
					}}
				/>
				<Link to={cardManagementRoute} className="link link-icn view-all-trans d-inline-block mx-0">
					All cards <img src={iconRightArrow} alt="leftArrowIcon" />
				</Link>
			</Col>
			<Col>
				<div className="page-nav">
					<LinkButton to={newCardRoute} icon={creditCardPlus} label="New Card" type="primary" />
					<IconButton
						icon={creditCardBan}
						label="Freeze Card"
						onClick={() => {
							setShowFreezeCardsModal(true)
						}}
						type="dark"
					/>
					<LinkButton
						to="/card-management/spending-limits"
						icon={creditCardConvert}
						label="Spending Limits"
						type="secondary"
					/>
				</div>
			</Col>
			{showFreezeCardsModal && (
				<FreezeCardsModal show={showFreezeCardsModal} onCloseModal={() => setShowFreezeCardsModal(false)} />
			)}
		</>
	)
}

interface HeaderBodyProps {
	accountData: AccountData | null
	selectedTab: number
}

function HeaderBody(props: HeaderBodyProps) {
	return (
		<>
			<Col xs={12}>
				{props.selectedTab === 0 && (
					<div className="tab-content show" id="tab-balance">
						<div className="balance">
							<Row className="align-items-center">
								<Col lg={'auto'}>
									<div className="available-funds">
										{props.accountData ? (
											<>
												<div className="value">
													{formatCurrency(
														props.accountData.details?.availableAmount ?? 0,
														props.accountData.details?.currency ?? null
													)}
												</div>
											</>
										) : (
											<>
												<div className="value">
													<span style={{ color: 'transparent' }}>S</span>
													<BeatLoader size={5} />
												</div>
											</>
										)}
										<div className="label">Available funds</div>
									</div>
								</Col>

								{/*<Col lg={'auto'}>*/}
								{/*    <AccountStatementOverview accountName={"The main account"}*/}
								{/*                              date={new Date(Date.now())}*/}
								{/*                              openingBalance={50000.00}*/}
								{/*                              totalCredits={25000.00}*/}
								{/*                              totalDebits={12000.00}*/}
								{/*                              closingBalance={63000.00}*/}
								{/*                              currency={"USD"}*/}
								{/*    />*/}
								{/*</Col>*/}
							</Row>
						</div>
					</div>
				)}
				{props.selectedTab === 1 && (
					<div className="tab-content cards show" id="tab-cards">
						<CardCarousel />
					</div>
				)}
			</Col>
		</>
	)
}

function HeaderFooter() {
	const tabButtons: TabButtonProps[] = [{ label: 'Last Month' }, { label: 'Last Week' }]

	const [selectedTab, setSelectedTab] = useData()
	const setSelectedTabWrapped = setSelectedTab as React.Dispatch<React.SetStateAction<number>>


	return (
		<>
			<Col xs="auto">
				<h2>Transactions</h2>
			</Col>
			<Col xs="auto">
				<TabButtons
					buttons={tabButtons}
					initialSelected={selectedTab as number}
					onTabChange={(i) => {
						setSelectedTabWrapped(i)
					}}
				/>
			</Col>
			<Col>
				<Link to="/transactions" className="link link-icn view-all-trans">
					All transactions <img src={iconRightArrow} alt="" />
				</Link>
			</Col>
		</>
	)
}

interface HeaderProps {
	accountData: AccountData | null
	onTransactionTabChange?: (i: number) => void
}

function Header(props: HeaderProps) {
	const [selectedTab, setSelectedTab] = useState(0)

	const headerControls = <HeaderControls selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
	const body = <HeaderBody accountData={props.accountData} selectedTab={selectedTab} />
	const footer = <HeaderFooter />

	return (
		<>
			<PageHeader title={'Overview'} controls={headerControls} body={body} footer={footer} />
		</>
	)
}

function Body(props: { accountData: AccountData | null }) {
	console.log('Redrawing body')

	const [selectedTab] = useData()

	let filter = {
		AccountId: props.accountData?.id,
		'CreatedOn.StartDate': (selectedTab === 0
			? new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
			: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
		).toISOString().substring(0,10),
		'CreatedOn.EndDate': new Date(Date.now()).toISOString().substring(0,10),
	}

	return <TransactionTable filters={filter} noFetch={props.accountData === null} />
}

const TabChange = React.createContext([0, () => {}] as [number, React.Dispatch<React.SetStateAction<number>>])

const useData = () => {
	const [context, setContext] = React.useContext(TabChange)

	return [context, setContext]
}

function DashboardPage() {
	const [accountData, setAccountData] = useState<AccountData | null>(null)
	const [transactionTab, setTransactionTab] = useState(0)
	console.log("Redrawing dashboard")

	useEffect(() => {
		if (accountData) {
			return
		}

		API.Accounts.GetActiveAccounts().then((r) => {
			if (r.success && r.data) {
				const acc = r.data.data.find((a) => a.isDefaultPaymentAccount)
				if (!acc) {
					return
				}

				API.Accounts.GetAccountDataById(acc.id).then((result) => {
					setAccountData(result.data ?? null)
				})
			}
		})
	})

	return (
		<>
			{/*<PageHeader title={"Overview"} controls={headerControls} body={body} footer={footer}/>*/}
			<TabChange.Provider value={[transactionTab, setTransactionTab]}>
				<Header accountData={accountData} />
				<BasePage>
					<Body accountData={accountData} />
				</BasePage>
			</TabChange.Provider>
		</>
	)
}

export default DashboardPage
