import { Link } from 'react-router-dom'
import React from 'react'

export interface ExtendingButtonProps extends React.HTMLProps<HTMLAnchorElement> {
	to?: string
	click?: MouseEvent
	width?: '90' | '125'
	state?: any
	icon?: string
	label?: string
	type?:
		| 'primary'
		| 'secondary'
		| 'dark'
		| 'light'
		| 'outline-primary'
		| 'outline-secondary'
		| 'outline-dark'
		| 'outline-light'
		| undefined
}

function ExtendingButton(props: ExtendingButtonProps) {
	const type = props.type ? 'btn-' + props.type : ''
	const classNames = `icn-btn  ${type} ${props.icon ? 'btn-icn' : ''}`
	const extendedClass = `extending-button-wrapper w-${props.width ?? '90'}`

	return (
		<div className={extendedClass}>
			<div className={classNames}>
				{props.to ? (
					<Link className={`inner ${type}`} to={props.to} onClick={props.onClick} {...props}>
						<img src={props.icon} alt="" />
						<span> {props.label} </span>
					</Link>
				) : (
					<a className={`inner ${type}`} {...props}>
						<img src={props.icon} alt="" />
						<span> {props.label} </span>
					</a>
				)}
			</div>
		</div>
	)
}

export default ExtendingButton
