//TODO: Move this to App.scss
import 'swiper/css'
import 'swiper/css/free-mode'
import 'react-phone-input-2/lib/style.css'
import './App.scss'
import { Layout } from './components/Layout'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import LoginPage from './components/pages/LoginPage'
import { isLoggedIn } from './api/Session'
import { useState } from 'react'
import ForgottenPasswordPage from './components/pages/ForgottenPasswordPage'

function App() {
	let routes

	const [, setLoggedIn] = useState(isLoggedIn())

	const onLoggedIn = (callback?: string | null) => {
		setLoggedIn(isLoggedIn())

		if (callback) {
			window.location.href = callback
		}
	}

	routes = (
		<>
			<Route
				key="login"
				path={'/login'}
				element={isLoggedIn() ? <Navigate to={'/'} /> : <LoginPage onLoggedIn={onLoggedIn} />}
			/>
			,
			<Route
				key="forgotten-password"
				path={'/forgotten-password'}
				element={isLoggedIn() ? <Navigate to={'/'} /> : <ForgottenPasswordPage />}
			/>
			,
			<Route
				key="main"
				path={'/*'}
				element={isLoggedIn() ? <Layout /> : <Navigate to={`/login${window.location.search}`} />}
			/>
		</>
	)

	return (
		<>
			<div className="App">
				<BrowserRouter>
					<Routes>{routes}</Routes>
				</BrowserRouter>
			</div>
		</>
	)
}

export default App
