import {clearSession, setCurrentUserId} from '../../api/Session'
import React, { MouseEvent, PropsWithChildren, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import API from 'api/API'
import { LoginResponse } from '../../api/Models'
import iconFullName from 'assets/imgs/icons/full-name.svg'
import iconPassword from 'assets/imgs/icons/password.svg'
import logo from 'assets/imgs/logo.svg'
import decor from 'assets/imgs/decor.png'
import { Bounce, toast, ToastContainer } from 'react-toastify'
import { Col, Button, Form, FormGroup, Row, Container } from 'react-bootstrap'

interface LoginPageProps {
	onLoggedIn: (callback?: string | null) => void
}

const toastOptions: any = {
	position: 'bottom-right',
	autoClose: 5000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: 'colored',
	transition: Bounce,
}

function Banner() {
	return (
		<Col md={6}>
			<InfoSide>
				<img src={decor} className="circle-decor" alt="" />
				<img src={logo} alt="logo" />
				<h1>
					Welcome to <span>Easy Payment Services</span>
				</h1>
				<p>
					Easy Payment Services Limited an EU Licensed Electronic Money Institution with full passport rights
					for the provision of payment services within the European Union.
				</p>
			</InfoSide>
		</Col>
	)
}

interface EmailFormProps {
	onSubmit: (email: string, password: string) => void
}

function EmailForm(props: EmailFormProps) {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const onKeyDown = async (e: React.KeyboardEvent) => {
		if(email ===  "" || password === ""){
			return;
		}

		if (e.key === "Return" || e.key === "Enter") {
			e.preventDefault()
			props.onSubmit(email, password)
		}
	}
	
	const onSubmit = (e: MouseEvent) => {
		e.preventDefault()
		props.onSubmit(email, password)
	}

	return (
		<Form>
			<FormGroup className={'form-group form-control-icn'}>
				<img src={iconFullName} alt="" />
				<input
					type="email"
					id="email"
					placeholder="Email"
					autoFocus={true}
					autoCapitalize="none"
					autoCorrect="off"
					title="This field is required."
					name="email"
					autoComplete="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					onKeyDown={onKeyDown}
					className="form-control"
				/>
			</FormGroup>
			<FormGroup className={'form-group form-control-icn'}>
				<img src={iconPassword} alt="" />
				<input
					type="password"
					id="password"
					name="password"
					placeholder="Password"
					autoComplete="password"
					className="form-control"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					onKeyDown={onKeyDown}
				/>
			</FormGroup>
			<Link to="/forgotten-password" className="forgot-password">
				Forgot your password?
			</Link>
			<Button className="btn-submit login-button" onClick={onSubmit} disabled={email ===  "" || password === ""}>
				LOGIN
			</Button>
		</Form>
	)
}

function VerificationCodeForm() {
	return (
		<Form>
			<Row>
				<Col lg={{ span: 6, offset: 3 }}>
					<FormGroup className="form-group">
						<VerifyBlockInputs>
							<Row>
								<Col>
									<input type="" className="form-control" value="0" maxLength={1} placeholder="" />
								</Col>
								<Col>
									<input type="" className="form-control" value="1" maxLength={1} placeholder="" />
								</Col>
								<Col>
									<input type="" className="form-control" value="2" maxLength={1} placeholder="" />
								</Col>
								<Col>
									<input type="" className="form-control" value="" maxLength={1} placeholder="" />
								</Col>
							</Row>
						</VerifyBlockInputs>
					</FormGroup>
				</Col>
			</Row>
			<FormHint>A code has been sent to your email.</FormHint>
			<Button className="btn-submit">LOGIN</Button>
		</Form>
	)
}

function MainForm(props: LoginPageProps) {
	const [searchParams] = useSearchParams()
	const [busy, setBusy] = useState(false)
	const [loginStep] = useState(0)
	

	const onSubmit = async (email: string, password: string) => {
		if (busy) {
			return
		}

		setBusy(true)

		const loginResult = await API.Users.Login({
			userName: email,
			password: password,
		})

		if (loginResult.success) {
			const data = loginResult.data as LoginResponse
			setCurrentUserId(data.userId!)
			props.onLoggedIn(searchParams.get('callback'))
			toast.success('Login successful!', toastOptions)
		} else {
			//TODO: handle errors and redirect
			toast.error(loginResult.error, toastOptions)
		}

		setBusy(false)
	}

	return (
		<Col md={6}>
			<FormSide>
				<h2>Login</h2>
				{loginStep === 0 ? <EmailForm onSubmit={onSubmit} /> : <VerificationCodeForm />}
			</FormSide>
		</Col>
	)
}

function InfoSide(props: PropsWithChildren<any>) {
	return <div className="info-side">{props.children}</div>
}

function FormSide(props: PropsWithChildren<any>) {
	return <div className="form-side">{props.children}</div>
}

function VerifyBlockInputs(props: PropsWithChildren<any>) {
	return <div className="verify-inputs">{props.children}</div>
}

function FormHint(props: PropsWithChildren<any>) {
	return <div className="form-hint">{props.children}</div>
}

function LoginPage(props: LoginPageProps) {
	clearSession()
	return (
		<>
			<section className="starter">
				<Container>
					<Row className={'align-items-center g-0'}>
						<Banner />
						<MainForm {...props} />
					</Row>
				</Container>
			</section>
			<ToastContainer />
		</>
	)
}

export default LoginPage
