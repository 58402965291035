import PageHeader from 'components/elements/PageHeader'
import BasePage from 'components/pages/BasePage'
import { TransactionData, TransactionType } from 'api/Models'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import API from 'api/API'
import { Col } from 'react-bootstrap'
import Label from '../elements/Label'
import Value from '../elements/Value'
import { toast } from 'react-toastify'
import { defaultToastOptions, formatCurrency } from '../../utils'
import { routeCardDetails, transactionChargeback } from '../../Routes'

function Body() {
	const routerParams = useParams()
	const id = routerParams['id']
	const [transaction, setTransaction] = useState<TransactionData>({
		id: '',
		tenantId: '',
		userId: '',
		accountId: '',
		cardId: '',
		obscuredPan: '',
		description: '',
		transactionType: TransactionType.Pos,
		referenceNumber: '',
		triggerIndicator: '',
		operationName: '',
		transactionId: '',
		dateCreatedUtc: '01-01-2024',
		processingType: '',
		amountOriginalTransaction: 0,
		currencyOriginalTransaction: '',
		amountTransactionBilling: 0,
		currencyTransactionBilling: '',
		feeClient: 0,
		feeIssuer: 0,
		cardAcceptorCountry: '',
		merchantCategoryCode: '',
		merchantId: '',
		cardBalance: 9,
		transactionStatus: '',
		providerReferenceNumber: 0,
		cardProcessorDateUtc: new Date(),
		sendSms: false,
		cardProcessorTransactionType: 0,
		responseText: '',
	})

	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true)
		API.Cards.GetTransactions({ TransactionId: id })
			.then((result) => {
				if (result.success && result.data!.data.length > 0) {
					setTransaction(result.data!.data[0])
				} else {
					toast.error('Failed to load transaction', defaultToastOptions())
				}
			})
			.finally(() => setLoading(false))
	}, [])

	//TODO: make this not inline
	const style = loading ? { filter: 'blur(5px)' } : {}

	return (
		<Col lg={{ span: 4, offset: 4 }} style={style}>
			<div className="transaction-val">
				{formatCurrency(transaction.amountOriginalTransaction, transaction.currencyOriginalTransaction)}
			</div>
			<div className="transaction-date">
				{new Date(transaction.dateCreatedUtc).toLocaleDateString()}
				<span>{new Date(transaction.dateCreatedUtc).toLocaleTimeString()}</span>
			</div>
			<div className="status approved d-table mx-auto">{transaction.transactionStatus}</div>
			<div className="transaction-info">
				<ul>
					<li>
						<Label>Tnx Source ID</Label>
						<Value bold>{transaction.id}</Value>
					</li>
					<li>
						<Label>Type</Label>
						<Value bold>{transaction.transactionType}</Value>
					</li>
					<li>
						<Label>Reservation synchronisation sequencer</Label>
						<Value>SEQ789</Value>
					</li>
					<li>
						<Label>Card</Label>
						<Value bold>
							<Link to={routeCardDetails(transaction.cardId)}> {transaction.cardId}</Link>
						</Value>
					</li>
					<li>
						<Label>Card Balance</Label>
						<Value bold>{transaction.cardBalance}</Value>
					</li>
					{/*<li>*/}
					{/*    <Label>Created</Label>*/}
					{/*    <Value>{transaction.dateCreatedUtc}</Value>*/}
					{/*</li>*/}
					{/*<li>*/}
					{/*    <Label>Processed</Label>*/}
					{/*    <Value>{transaction.cardProcessorDateUtc}</Value>*/}
					{/*</li>*/}
					<li>
						<Label>Reference Id</Label>
						<Value>{transaction.referenceNumber}</Value>
					</li>
					<li>
						<hr />
					</li>
					<li>
						<Label>Merchant Name</Label>
						<Value bold extraClassName="transaction-type">
							{transaction.description}
						</Value>
					</li>
				</ul>
				<div className="form-action">
					<Link to={transactionChargeback(id ?? '')}>Chargeback</Link>
				</div>
			</div>
		</Col>
	)
}

function TransactionPage() {
	const location = useLocation()
	const state = location.state

	// if (!state) {
	//     return <Navigate to={"/transactions"}/>
	// }

	return (
		<>
			<PageHeader
				backLink={state?.prev ? state.prev : '/transactions'}
				title={'Transaction Details'}

				// body={<HeaderBody onFilterChange={onFilterChange}/>}
			/>
			<BasePage className="page-holder transaction-details">
				<Body />
			</BasePage>
		</>
	)
}

export default TransactionPage
