export interface SpendingAnalysisPageProps {}

function SpendingAnalysisPage(props: SpendingAnalysisPageProps) {
	return (
		<div className="content-page">
			<h2>Spending Analysis Stub</h2>
		</div>
	)
}

export default SpendingAnalysisPage
