import PageHeader from 'components/elements/PageHeader'
import BasePage from 'components/pages/BasePage'
import React, { HTMLProps, ReactElement, useEffect, useMemo, useState } from 'react'
import Select, { ActionMeta, components, OptionProps, SingleValue, SingleValueProps } from 'react-select'
import countryList from 'react-select-country-list'
import API from 'api/API'
import { Address } from 'api/Models'
import { Link, useLocation } from 'react-router-dom'
import { CardTypes, NewCardReviewDTO } from 'dtos/DTOs'
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import { FormGroup } from 'react-bootstrap'

interface SelectOption {
	label: string
	value: string
}

interface SelectProps<T> {
	placeholder?: string
	value?: T
	readonly options: readonly (T & {})[]
	onChange?: (value: T | null) => void
	represent?: (value: T) => string | ReactElement
	getLabel: (value: T) => string
	defaultValue?: T
	styles?: any
}

function CustomSelect<T>(props: SelectProps<T>) {
	const onChange = (option: SingleValue<T>, actionMeta: ActionMeta<T>) => {
		if (props.onChange) {
			props.onChange(option)
		}
	}

	let value: SingleValue<T> = props.value as T
	if (value === undefined) {
		value = null
	}

	const { Option, SingleValue } = components
	const IconOption = (optionProps: OptionProps<T>) => (
		<Option {...optionProps}>
			{props.represent ? props.represent(optionProps.data) : props.getLabel(optionProps.data)}
		</Option>
	)

	const IconSingleValue = (singleValueProps: SingleValueProps<T>) => (
		<SingleValue {...singleValueProps}>
			{props.represent ? props.represent(singleValueProps.data) : props.getLabel(singleValueProps.data)}
		</SingleValue>
	)

	return (
		<Select
			options={props.options}
			//onChange={onChange}
			placeholder={props.placeholder}
			getOptionLabel={(option: T) => props.getLabel(option)}
			getOptionValue={(option: T) => props.getLabel(option)}
			components={{ Option: IconOption, SingleValue: IconSingleValue }}
			styles={props.styles}
		/>
	)
}

function ValidateEmbossName(value: string | undefined) {
	//Name and surname in Latin letters to be printed on the card.
	//Only capital letters.
	//Max. 26 characters, period, dash, apostrophe, comma and space allowed.
	if (value === undefined) {
		return false
	}
	value = value.toUpperCase()
	value = value.trim()
	return !!value.match(/^([A-Z]|\.|-|'|,| ){1,26}$/)
}

function ValidateEmbossLine4(value: string | undefined) {
	//Additional emboss line printed on the 4th line of the card.
	//matches any uppercase letter from A to Z, any digit from 0 to 9 Max, matches a literal period.
	// 26 characters, matches literal comma, single quote, space
	if (value === undefined) {
		return false
	}
	value = value.toUpperCase()
	value = value.trim()
	return !!value.match(/^([A-Z]|[0-9]|\.|-|'|,| ){0,26}$/)
}

function ValidateAlias(value: string | undefined) {
	return !!value && value.length <= 100
}

function ValidateCredit(value: string | undefined) {
	return !!value && value.length <= 100
}

function ValidateFormData(value: FormData): FormDataValidation {
	return {
		account: !!value.account,
		user: !!value.user,
		alias: ValidateAlias(value.alias),
		cardType: !!value.cardType,
		credit: ValidateCredit(value.credit),
		embossName: ValidateEmbossName(value.embossName),
		program: !!value.program,
		embossLine4: ValidateEmbossLine4(value.embossLine4),
		address: true,
	}
}

interface FormData {
	account?: SelectOption | null
	user?: SelectOption | null
	alias?: string
	country?: SelectOption | null
	cardType?: SelectOption | null
	credit?: string
	program?: SelectOption | null
	address: Address
	embossName?: string
	embossLine4?: string
}

interface FormDataValidation {
	account: boolean
	user: boolean
	alias: boolean
	cardType: boolean
	credit: boolean
	address: boolean
	program: boolean
	embossName: boolean
	embossLine4: boolean
}

function requiredValidationsAreGoodAndShit(validation: FormDataValidation, formData: FormData) {
	if (
		validation.account &&
		validation.user &&
		validation.alias &&
		validation.cardType &&
		validation.credit &&
		validation.program &&
		validation.embossName
	) {
		return true
	}
}

function Form() {
	const location = useLocation()

	const cardTypes = CardTypes.map((a) => ({ value: a.value, label: a.displayName }))
	const [loading, setLoading] = useState(false)
	const [accounts, setAccounts] = useState<SelectOption[]>([])
	const [users, setUsers] = useState<SelectOption[]>([])
	const [cardPrograms, setCardPrograms] = useState<SelectOption[]>([])
	const [formData, setFormData] = useState<FormData>({
		address: { street: '', houseNumber: '', city: '', postcode: '', countryCode: '', alias: '' },
	})

	const countryOptions = useMemo(() => countryList().getData(), [])

	useEffect(() => {
		const state = location.state as NewCardReviewDTO | null

		async function fetchData() {
			await API.Accounts.GetActiveAccounts().then((r) => {
				if (r.success && r.data) {
					const accounts = r.data.data
					setAccounts(accounts.map((u) => ({ value: u.id, label: u.name })))
				}
			})

			await API.Users.GetUsers().then((r) => {
				if (r.success && r.data) {
					const users = r.data.data
					setUsers(users.map((u) => ({ value: u.id, label: u.email })))
				}
			})

			await API.Tenant.GetCardPrograms().then((r) => {
				if (r.success && r.data) {
					const users = r.data.data
					setCardPrograms(users.map((u) => ({ value: u.program, label: u.program })))
				}
			})
		}

		setLoading(true)

		fetchData().finally(() => {
			if (!!state) {
				console.log('Setting State')
				setFormData({
					account: accounts.find((a) => a.value === state.account?.id),
					user: users.find((u) => u.value === state.user?.id),
					alias: state.alias,
					country: countryOptions.find((c) => c.value === state.address?.countryCode),
					cardType: cardTypes.find((c) => c.value === state.cardType.value),
					credit: state.credit?.toString(),
					program: cardPrograms.find((c) => c.value === state.program),
					address: state.address ?? {
						street: '',
						houseNumber: '',
						city: '',
						postcode: '',
						countryCode: '',
						alias: '',
					},
					embossName: state.embossName,
					embossLine4: state.embossLine4,
				})
			}
			setLoading(false)
		})
	}, [])

	const onSubmit = (e: React.MouseEvent<HTMLAnchorElement>, formData: FormData) => {
		const validation = ValidateFormData(formData)
		//setFormDataValidation(validation)
		if (!requiredValidationsAreGoodAndShit(validation, formData)) {
			console.log('BAD DATA ', validation)
			e.preventDefault()
		}
	}

	return (
		<>
			<div className="col-lg-4 offset-lg-4">
				{loading && <CircleSpinnerOverlay />}
				<form>
					<div className="form-block">
						<div className="form-group">
							{/*<div className="form-heading">User:</div>*/}
							<CustomSelect
								options={users}
								placeholder={'User'}
								getLabel={(option) => option?.label ?? ''}
								value={formData.user}
								onChange={(value) => setFormData({ ...formData, user: value })}
							/>
						</div>
						<div className="form-group">
							{/*<div className="form-heading">Account:</div>*/}
							<CustomSelect
								options={accounts}
								placeholder={'Account'}
								value={formData.account}
								getLabel={(option) => option?.label ?? ''}
								onChange={(value) => {
									console.log('Good shit', value)
									setFormData({ ...formData, account: value })
								}}
							/>
						</div>
						<div className="form-group">
							<ValidatedInput
								type="text"
								name="alias"
								placeholder="Alias"
								className="form-control"
								validate={ValidateAlias}
								value={formData.alias}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									setFormData({ ...formData, alias: e.target.value?.toString() })
								}
								hint={'Alias is required'}
							/>
						</div>
						<div className="form-group">
							<ValidatedInput
								type="text"
								name="embossName"
								placeholder="Emboss Name"
								className="form-control"
								validate={(value) => value !== undefined && ValidateEmbossName(value)}
								value={formData.embossName}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									setFormData({ ...formData, embossName: e.target.value?.toString() })
								}
								hint={
									'Only letters, digits, period, dash, apostrophe, comma and space allowed. Min 1 character. Max 26 characters.'
								}
							/>
						</div>
						<div className="form-group">
							<ValidatedInput
								type="text"
								name="embossName"
								placeholder="Emboss Line 4"
								className="form-control"
								validate={(value) => value !== undefined && ValidateEmbossLine4(value)}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									setFormData({ ...formData, embossLine4: e.target.value?.toString() })
								}
								hint={
									'Only letters, digits, period, dash, apostrophe, comma and space allowed. Max 26 characters.'
								}
							/>
						</div>
						<FormGroup className="formGroup">
							<CustomSelect
								options={cardTypes}
								getLabel={(option) => option?.label ?? ''}
								value={formData.cardType}
								placeholder={'Type'}
								onChange={(value) => setFormData({ ...formData, cardType: value })}
							/>
						</FormGroup>
						<div className="form-group">
							<CustomSelect
								options={cardPrograms}
								getLabel={(option) => option?.label ?? ''}
								value={formData.program}
								placeholder={'Program'}
								onChange={(value) => setFormData({ ...formData, program: value })}
							/>
						</div>
						<div className="form-group">
							<ValidatedInput
								type="number"
								name="creditLimit"
								placeholder="Credit Limit"
								className="form-control"
								value={formData.credit}
								onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
									let credit = parseInt(e.target.value?.toString())
									setFormData({ ...formData, credit: credit.toString() })
								}}
								validate={(value) => {
									return value !== undefined && !!value.match(/^[0-9]{0,30}$/)
								}}
							/>
						</div>
					</div>
					{(formData.cardType?.value === 'VirtualWithPlastic' || formData.cardType?.value === 'Plastic') && (
						<div className="form-block">
							<div className="form-hint">
								Address <span>(visible only for physical card)</span>:
							</div>
							<div className="form-heading">Address:</div>
							<div className="form-group ">
								<input
									type="text"
									autoComplete="address-level2"
									name="street"
									placeholder="Street"
									className="form-control"
								/>
							</div>
							<div className="form-group ">
								<input
									type="text"
									autoComplete="address-level1"
									name="houseNumber"
									placeholder="Apartment, suite, etc."
									className="form-control"
								/>
							</div>
							<div className="form-group ">
								<input
									type="text"
									autoComplete="address-level3"
									name="city"
									placeholder="City"
									className="form-control"
								/>
							</div>
							<div className="form-group ">
								<input
									type="text"
									autoComplete="postal-code"
									name="postal-code"
									placeholder="Postal Code"
									className="form-control"
								/>
							</div>
							<div className="form-group ">
								<Select options={countryOptions} value={formData.country} />
								{/* onChange={(value: SelectOption) => {
                                        setFormData({...formData, country: value})
                                    }}/> */}
							</div>
						</div>
					)}
					<div className="form-action">
						<Link
							to={'/card-management/new-card-review'}
							className="btn-submit"
							onClick={(e) => onSubmit(e, formData)}
							state={GetReviewState(formData)}
						>
							Continue
						</Link>
						{/*<Link to={"/card-management/new-card-review"} className="btn-submit" state={GetReviewState(formData)}>Continue</Link>*/}
					</div>
				</form>
			</div>
		</>
	)
}

function GetReviewState(formData: FormData): NewCardReviewDTO {
	console.log('FORM DATA', formData)
	return {
		account: {
			name: formData.account?.label as string,
			id: formData.account?.value as string,
		},
		user: {
			name: formData.user?.label as string,
			id: formData.user?.value as string,
		},
		cardType: {
			name: formData.cardType?.label as string,
			value: formData.cardType?.value as string,
		},
		embossName: formData.embossName?.toUpperCase() as string,
		embossLine4: formData.embossLine4?.toUpperCase(),
		alias: formData.alias as string,
		program: formData.program?.value as string,
	}
}

interface ValidatedInputProps extends HTMLProps<HTMLInputElement> {
	hint?: string
	validate: (value: string | undefined) => boolean
}

function ValidatedInput(props: ValidatedInputProps) {
	const [isValid, setIsValid] = useState<boolean>(true)

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let targetValue = e.target.value
		setIsValid(props.validate(targetValue))

		if (props.onChange) {
			props.onChange(e)
		}
	}

	const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		let targetValue = e.target.value
		setIsValid(props.validate(targetValue))

		if (props.onInput) {
			props.onInput(e)
		}
	}

	const onChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
		let targetValue = e.target.value

		if (targetValue === '') {
			targetValue = '0'
		}

		setIsValid(props.validate(targetValue))

		if (props.onChange) {
			props.onChange(e)
		}
	}

	const onInputNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
		let targetValue = e.target.value

		if (targetValue === '') {
			targetValue = '0'
		}

		targetValue = parseInt(targetValue).toString()

		setIsValid(props.validate(targetValue))

		if (props.onInput) {
			props.onInput(e)
		}
	}

	const cleanProps = { ...props, validate: undefined, hint: undefined }
	return (
		<>
			<input
				{...cleanProps}
				className={props.className + (isValid ? ' invalid' : '')}
				value={props.value}
				onInput={props.type === 'number' ? onInputNumber : onInput}
				onChange={props.type === 'number' ? onChangeNumber : onChange}
			/>
			{!isValid && props.hint && <div className="invalid-hint">{props.hint}</div>}
		</>
	)
	//return <input type={props.type} placeholder={props.placeholder} className="form-control"/>
}

function NewCardPage() {
	const getAccounts = async () => {
		const result = await API.Accounts.GetActiveAccounts()
		console.log(result)
	}

	//--- Debug methods
	const getUsers = async () => {
		const result = await API.Users.GetUsers()
		console.log(result)
	}

	const getRoles = async () => {
		const result = await API.Users.GetRoles()
		console.log(result)
	}
	const getCards = async () => {
		const result = await API.Cards.GetCards({ withBalance: true })
		console.log(result)
	}

	const getBalance = async () => {
		const result = await API.Accounts.GetBalance('1258676882431246336')
		console.log(result)
	}

	const getStatement = async () => {
		const result = await API.Accounts.GenerateAccountStatements(
			'1258676882431246336',
			new Date('2024-08-01'),
			new Date('2024-09-01')
		)
		console.log(result)
	}

	const getAccountSearchThing = async () => {
		const result = await API.Accounts.Search('BG02EAPS40084004G00004')
		console.log(result)
	}
	//--- END Debug methods

	return (
		<>
			<PageHeader title={'Order New Card'} />
			<BasePage>
				{/*<>*/}
				{/*	DEBUG Buttons:*/}
				{/*	<Button className="btn btn-outline-primary" onClick={getAccounts}>*/}
				{/*		Get Accounts*/}
				{/*	</Button>*/}
				{/*	<Button variant="outline-primary" onClick={getUsers}>*/}
				{/*		Get Users*/}
				{/*	</Button>*/}
				{/*	<Button variant="outline-primary" onClick={getRoles}>*/}
				{/*		Get Roles*/}
				{/*	</Button>*/}
				{/*	<Button variant="outline-primary" onClick={getCards}>*/}
				{/*		Get Cards*/}
				{/*	</Button>*/}
				{/*	<Button variant="outline-primary" onClick={getStatement}>*/}
				{/*		Get Statement*/}
				{/*	</Button>*/}
				{/*	<Button variant="outline-primary" onClick={getBalance}>*/}
				{/*		Get Balance*/}
				{/*	</Button>*/}
				{/*	<Button variant="outline-primary" onClick={getAccountSearchThing}>*/}
				{/*		Account Search*/}
				{/*	</Button>*/}
				{/*</>*/}
				<Form />
			</BasePage>
		</>
	)
}

export default NewCardPage
