import React, { MouseEvent, PropsWithChildren, useState } from 'react'
import API from 'api/API'
import { InitiateChangePasswordResponse } from '../../api/Models'
import iconFullName from 'assets/imgs/icons/full-name.svg'
import iconPassword from 'assets/imgs/icons/password.svg'
import iconMessage from 'assets/imgs/icons/message.svg'
import iconMail from 'assets/imgs/icons/mail.svg'
import iconCheckBox from 'assets/imgs/icons/check-square.svg'
import iconSuccess from 'assets/imgs/icons/yes.svg'
import iconFailure from 'assets/imgs/icons/no.svg'
import logo from 'assets/imgs/logo.svg'
import decor from 'assets/imgs/decor.png'
import { Bounce, toast, ToastContainer } from 'react-toastify'
import { Col, Button, Form, FormGroup, Row, Container } from 'react-bootstrap'
import {
	ValidatePassword, ValidatePasswordDigit,
	ValidatePasswordLength,
	ValidatePasswordLowercase, ValidatePasswordSpecialCharacter,
	ValidatePasswordUpperCase
} from '../../utils'

const toastOptions: any = {
	position: 'bottom-right',
	autoClose: 5000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: 'colored',
	transition: Bounce,
}

function Banner() {
	return (
		<Col md={6}>
			<InfoSide>
				<img src={decor} className="circle-decor" alt="" />
				<img src={logo} alt="logo" />
				<h1>
					Welcome to <span>Easy Payment Services</span>
				</h1>
				<p>
					Easy Payment Services Limited an EU Licensed Electronic Money Institution with full passport rights
					for the provision of payment services within the European Union.
				</p>
			</InfoSide>
		</Col>
	)
}

interface ForgottenFormProps {
	onSubmit: (email: string) => void
}

interface ResetPasswordFormProps {
	onSubmit: (oneTimePassword: string, tempPassword: string, newPassword: string) => void
}

interface PasswordValidation {
	length : boolean,
	uppercase: boolean,
	lowercase: boolean,
	digit: boolean,
	special: boolean
	match: boolean
}

function ChangePasswordForm(props: ResetPasswordFormProps) {
	const [newPassword, setNewPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [tempPassword, setTempPassword] = useState('')
	const [oneTimePassword, setOneTimePassword] = useState('')
	const [validation, setValidation] = useState<PasswordValidation>({
		length : false,
		uppercase: false,
		lowercase: false,
		digit: false,
		special: false,
		match: false,
	})

	const onKeyDown = async (e: React.KeyboardEvent) => {
		if (e.key === 'Return' || e.key === 'Enter') {
			e.preventDefault()
			submit()
		}
	}

	const onSubmit = (e: MouseEvent) => {
		e.preventDefault()
		submit()
	}

	const submit = () => {
		const [validPassword, error] = ValidatePassword(newPassword)
		if (!validPassword) {
			toast.error(`Invalid password: ${error}`, toastOptions)
			return
		}

		if (confirmPassword !== newPassword) {
			toast.error("Confirmation doesn't match", toastOptions)
			return
		}

		props.onSubmit(oneTimePassword, tempPassword, newPassword)
	}

	const passwordValidation = (password :string, confirmation:string) => {
		const [length] = ValidatePasswordLength(password)
		const [uppercase] = ValidatePasswordUpperCase(password)
		const [lowercase] = ValidatePasswordLowercase(password)
		const [digit] = ValidatePasswordDigit(password)
		const [special] = ValidatePasswordSpecialCharacter(password)
		const match = password === confirmation

		setValidation(
			{
				length: length,
				uppercase: uppercase,
				lowercase: lowercase,
				digit: digit,
				special: special,
				match: match
			},
		)
	}

	return (
		<Form>
			<h3>Create a new Password</h3>
			<label>One Time Password from SMS</label>
			<FormGroup className={'form-group form-control-icn'}>
				<img src={iconMessage} alt="" />
				<input
					type="text"
					id="oneTimePassword"
					placeholder="One Time Password"
					autoFocus={true}
					autoCapitalize="none"
					autoCorrect="off"
					title="Enter the one time password received via SMS"
					name="oneTimePassword"
					value={oneTimePassword}
					onChange={(e) => setOneTimePassword(e.target.value)}
					onKeyDown={onKeyDown}
					className="form-control"
				/>
			</FormGroup>
			<label>Temporary Password from Email</label>
			<FormGroup className={'form-group form-control-icn'}>
				<img src={iconMail} alt="" />
				<input
					type="password"
					id="tempPassword"
					placeholder="Temporaty Password"
					autoFocus={true}
					autoCapitalize="none"
					autoCorrect="off"
					title="Enter the temporary password received via Email"
					name="tempPassword"
					value={tempPassword}
					onChange={(e) => setTempPassword(e.target.value)}
					onKeyDown={onKeyDown}
					className="form-control"
				/>
			</FormGroup>

			<Row>
				<Col sm={6}>
					<label>New Password</label>
					<FormGroup className={'form-group form-control-icn'}>
						<img src={iconPassword} alt="" />
						<input
							type="password"
							id="newPassword"
							placeholder="Create a new password"
							autoFocus={true}
							autoCapitalize="none"
							autoCorrect="off"
							title="New Password"
							name="confirmPassword"
							value={newPassword}
							onChange={(e) => {
								setNewPassword(e.target.value)
								passwordValidation(e.target.value, confirmPassword)
							}}
							onKeyDown={onKeyDown}
							className="form-control"
						/>
					</FormGroup>
				</Col>
				<Col sm={6}>
					<label>Confirm Password</label>
					<FormGroup className={'form-group form-control-icn'}>
						<img src={iconCheckBox} alt="" />
						<input
							type="password"
							id="confirmPassword"
							placeholder="Repeat the new password"
							autoFocus={true}
							autoCapitalize="none"
							autoCorrect="off"
							title="Confirm Password"
							name="confirmPassword"
							value={confirmPassword}
							onChange={(e) => {
								setConfirmPassword(e.target.value)
								passwordValidation(newPassword, e.target.value)
							}}
							onKeyDown={onKeyDown}
							className="form-control"
						/>
					</FormGroup>
				</Col>
			</Row>
			<div className={'d-flex flex-wrap'}>
				<PasswordValidationCheck valid={validation.length} text="At least 12 characteres long" />
				<PasswordValidationCheck valid={validation.digit} text="At least one number" />
				<PasswordValidationCheck valid={validation.lowercase} text="At least one lowercase letter" />
				<PasswordValidationCheck valid={validation.uppercase} text="At least one uppercase letter" />
				<PasswordValidationCheck valid={validation.special} text="At least one special character" />
				<PasswordValidationCheck valid={validation.match} text="Passwords do not match" />
			</div>

			<Button className="btn-submit" onClick={onSubmit}>
				Reset Password
			</Button>
		</Form>
	)
}

interface PasswordValidationCheckProps {
	valid: boolean
	text: string
}

function PasswordValidationCheck(props: PasswordValidationCheckProps) {
	const icon = props.valid ? iconSuccess : iconFailure
	return (
		<div className={'password-validation ' + (props.valid ? 'valid' : '')}>
			<img src={icon} alt="" />
			{props.text}
		</div>
	)
}

function ForgottenPasswordForm(props: ForgottenFormProps) {
	const [email, setEmail] = useState('')

	const onKeyDown = async (e: React.KeyboardEvent) => {
		if (e.key === 'Return' || e.key === 'Enter') {
			e.preventDefault()
			props.onSubmit(email)
		}
	}

	const onSubmit = (e: MouseEvent) => {
		e.preventDefault()
		props.onSubmit(email)
	}

	return (
		<Form>
			<h2>Reset Your Login</h2>
			<label>Your Email Associated with the Account</label>
			<FormGroup className={'form-group form-control-icn'}>
				<img src={iconFullName} alt="" />
				<input
					type="email"
					id="email"
					placeholder="Email"
					autoFocus={true}
					autoCapitalize="none"
					autoCorrect="off"
					title="This field is required."
					name="email"
					autoComplete="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					onKeyDown={onKeyDown}
					className="form-control"
				/>
			</FormGroup>
			<label className="small">We will send you an email with instructions to reset your password</label>
			<Button className="btn-submit" onClick={onSubmit}>
				Request Password Password
			</Button>
		</Form>
	)
}

// function VerificationCodeForm() {
// 	return (
// 		<Form>
// 			<Row>
// 				<Col lg={{ span: 6, offset: 3 }}>
// 					<FormGroup className="form-group">
// 						<VerifyBlockInputs>
// 							<Row>
// 								<Col>
// 									<input type="" className="form-control" value="0" maxLength={1} placeholder="" />
// 								</Col>
// 								<Col>
// 									<input type="" className="form-control" value="1" maxLength={1} placeholder="" />
// 								</Col>
// 								<Col>
// 									<input type="" className="form-control" value="2" maxLength={1} placeholder="" />
// 								</Col>
// 								<Col>
// 									<input type="" className="form-control" value="" maxLength={1} placeholder="" />
// 								</Col>
// 							</Row>
// 						</VerifyBlockInputs>
// 					</FormGroup>
// 				</Col>
// 			</Row>
// 			<FormHint>A code has been sent to your email.</FormHint>
// 			<Button className="btn-submit">LOGIN</Button>
// 		</Form>
// 	)
// }

interface ResponseData {
	tokenId: string
	userId: string
	tenantId: string
}

function MainForm() {
	const [busy, setBusy] = useState(false)
	const [step, setStep] = useState(1)
	const [responseData, setResponseData] = useState<ResponseData>()

	const onSubmitForgottenPassword = async (email: string) => {
		if (busy) {
			return
		}

		setBusy(true)

		const loginResult = await API.Users.InitiateForgotPassword(email)

		if (loginResult.success) {
			if (busy) {
				return
			}
			setBusy(true)
			const data = loginResult.data as InitiateChangePasswordResponse
			setResponseData({
				tokenId: data.tokenId ?? '',
				userId: data.userId ?? '',
				tenantId: data.tenantId ?? '',
			})
			toast.success('Request sent!', toastOptions)
			setStep(1)
			setBusy(false)
		} else {
			//TODO: handle errors and redirect
			toast.error(loginResult.error, toastOptions)
		}

		setBusy(false)
	}

	const onSubmitResetPassword = async (oneTimePassword: string, tempPassword: string, newPassword: string) => {
		if (busy) {
			return
		}

		setBusy(true)

		const loginResult = await API.Users.ResetPassword(
			oneTimePassword,
			tempPassword,
			newPassword,
			responseData!.tokenId,
			responseData!.userId,
			responseData!.tenantId
		)

		if (loginResult.success) {
			if (busy) {
				return
			}
			setBusy(true)
			toast.success('Password reset!', toastOptions)
			window.location.href = '/'
			setStep(1)
			setBusy(false)
		} else {
			//TODO: handle errors and redirect
			toast.error(loginResult.error, toastOptions)
		}

		setBusy(false)
	}

	return (
		<Col md={6}>
			{/*<FormSide>*/}
			{/*	<ForgottenPasswordForm onSubmit={onSubmitForgottenPassword} />*/}
			{/*</FormSide>*/}
			{step === 0 ? (
				<FormSide>
					<ForgottenPasswordForm onSubmit={onSubmitForgottenPassword} />
				</FormSide>
			) : (
				<FormSide className="password-reset">
					<ChangePasswordForm onSubmit={onSubmitResetPassword} />
				</FormSide>
			)}
		</Col>
	)
}

function InfoSide(props: PropsWithChildren<any>) {
	return <div className="info-side">{props.children}</div>
}

function FormSide(props: PropsWithChildren<any>) {
	return <div className={'form-side ' + props.className}>{props.children}</div>
}

// function VerifyBlockInputs(props: PropsWithChildren<any>) {
// 	return <div className="verify-inputs">{props.children}</div>
// }
//
// function FormHint(props: PropsWithChildren<any>) {
// 	return <div className="form-hint">{props.children}</div>
// }

function ForgottenPasswordPage() {
	return (
		<>
			<section className="starter">
				<Container>
					<Row className={'align-items-center g-0'}>
						<Banner />
						<MainForm />
					</Row>
				</Container>
			</section>
			<ToastContainer />
		</>
	)
}

export default ForgottenPasswordPage
