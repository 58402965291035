import PageHeader from 'components/elements/PageHeader'
import BasePage from 'components/pages/BasePage'
import React, { HTMLProps, ReactElement, useMemo, useState } from 'react'
import Select, { ActionMeta, components, OptionProps, SingleValue, SingleValueProps } from 'react-select'
import countryList from 'react-select-country-list'
import API from 'api/API'
import { TransactionData } from 'api/Models'
import { Link, redirect, useParams } from 'react-router-dom'
import { ChargebackReviewDTO } from 'dtos/DTOs'
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import PhoneInput, { CountryData } from 'react-phone-input-2'

interface SelectOption {
	label: string
	value: string
}

interface SelectProps<T> {
	placeholder?: string
	value?: T
	readonly options: readonly (T & {})[]
	onChange?: (value: T | null) => void
	represent?: (value: T) => string | ReactElement
	getLabel: (value: T) => string
	defaultValue?: T
	styles?: any
}

function CustomSelect<T>(props: SelectProps<T>) {
	const onChange = (option: SingleValue<T>, actionMeta: ActionMeta<T>) => {
		if (props.onChange) {
			props.onChange(option)
		}
	}

	let value: SingleValue<T> = props.value as T
	if (value === undefined) {
		value = null
	}

	const { Option, SingleValue } = components
	const IconOption = (optionProps: OptionProps<T>) => (
		<Option {...optionProps}>
			{props.represent ? props.represent(optionProps.data) : props.getLabel(optionProps.data)}
		</Option>
	)

	const IconSingleValue = (singleValueProps: SingleValueProps<T>) => (
		<SingleValue {...singleValueProps}>
			{props.represent ? props.represent(singleValueProps.data) : props.getLabel(singleValueProps.data)}
		</SingleValue>
	)

	return (
		<Select
			options={props.options}
			//onChange={onChange}
			placeholder={props.placeholder}
			getOptionLabel={(option: T) => props.getLabel(option)}
			getOptionValue={(option: T) => props.getLabel(option)}
			components={{ Option: IconOption, SingleValue: IconSingleValue }}
			styles={props.styles}
		/>
	)
}

function ValidateEmbossName(value: string | undefined) {
	//Name and surname in Latin letters to be printed on the card.
	//Only capital letters.
	//Max. 26 characters, period, dash, apostrophe, comma and space allowed.
	if (value === undefined) {
		return false
	}
	value = value.toUpperCase()
	value = value.trim()
	return !!value.match(/^([A-Z]|\.|-|'|,| ){1,26}$/)
}

function ValidatePhone(form: FormData | undefined) {
	if (!form || !form.phoneCountryCode || !form.phoneCode || !form.phoneNumber) {
		return false
	}

	if (!form.phoneNumber.startsWith(form.phoneCode)) {
		return false
	}

	if (form.phoneNumber.length - form.phoneCode.length < 6) {
		return false
	}

	return true
}

function ValidateName(value: string | undefined) {
	return !!value && value.length <= 100
}

function ValidateCredit(value: string | undefined) {
	return !!value && value.length <= 100
}

function ValidateFormData(value: FormData): FormDataValidation {
	return {
		fullName: ValidateName(value.fullName),
		phoneCountryCode: true,
		phoneCode: true,
		phoneNumber: true,
		reason: !!value.reason,
	}
}

interface FormData {
	reason?: SelectOption | null
	phoneCountryCode?: string
	additionalData?: string
	phoneCode?: string
	phoneNumber?: string
	fullName?: string
}

interface FormDataValidation {
	reason: boolean
	phoneCountryCode: boolean
	phoneCode: boolean
	phoneNumber: boolean
	fullName: boolean
}

function requiredValidationsAreGoodAndShit(validation: FormDataValidation, formData: FormData) {
	return true
}

enum ChargebackReason {
	UnauthorizedTransaction = 'Unauthorized_Transaction',
	DuplicateCharge = 'Duplicate_Charge',
	GoodsOrServicesNotReceived = 'Goods_or_Services_not_Received',
	GoodOrServicesNotAsDescribed = 'Good_or_Services_not_as_Described',
	BillingError = 'Billing_Error',
	FraudulentTransaction = 'Fraudulent_Transaction',
	CancellationOrRefundIssue = 'Cancellation_or_Refund_Issue',
	Other = 'Other',
}

function Form(props: { transaction: TransactionData | undefined }) {
	const reasons = Object.values(ChargebackReason).map((a) => ({ value: a, label: a.replaceAll('_', ' ') }))

	const [loading, setLoading] = useState(false)
	const [cardPrograms, setCardPrograms] = useState<SelectOption[]>([])
	const [countryCode, setCountryCode] = useState('bg')
	const [formData, setFormData] = useState<FormData>({})

	const countryOptions = useMemo(() => countryList().getData(), [])

	const onSubmit = (e: React.MouseEvent<HTMLAnchorElement>, formData: FormData) => {
		const validation = ValidateFormData(formData)
		//setFormDataValidation(validation)

		if (!ValidatePhone(formData)) {
			console.log('BAD PHONE', formData)
			e.preventDefault()
		}

		if (!formData.reason) {
			console.log('NO REASON', formData)
			e.preventDefault()
		}

		// if (!requiredValidationsAreGoodAndShit(validation, formData)) {
		// 	console.log('BAD DATA ', validation)
		// 	e.preventDefault()
		// }
	}

	return (
		<>
			<div className="col-lg-4 offset-lg-4">
				{(loading || props.transaction === undefined) && <CircleSpinnerOverlay />}
				<form>
					<div className="form-block">
						<div className="form-group">
							<CustomSelect
								options={reasons}
								placeholder={'Reason'}
								getLabel={(option) => option?.label ?? ''}
								value={formData.reason}
								onChange={(value) =>{
									console.log("reason on change", value)
									setFormData({ ...formData, reason: value })
								}}
							/>
						</div>
						<div className="form-group">
							<ValidatedInput
								type="text"
								name="fullName"
								placeholder="Full Name"
								className="form-control"
								validate={ValidateName}
								value={formData.fullName}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									setFormData({ ...formData, fullName: e.target.value?.toString() })
								}
								hint={'Name is required'}
							/>
						</div>
						<div className="form-group">
							{/*TODO: Style this */}
							<PhoneInput
								country={countryCode}
								value={formData.phoneNumber}
								containerClass={'form-control phone-input-container'}
								inputClass={'phone-input'}
								buttonClass={'phone-input-button'}
								onChange={(phone, data) => {
									console.log(phone)
									console.log(data)
									setFormData({
										...formData,
										phoneCountryCode: (data as CountryData).countryCode,
										phoneCode: (data as CountryData).dialCode,
										phoneNumber: phone,
									})
								}}
							/>
						</div>
						<div className="form-group">
							<textarea className="form-text-area" maxLength={1000} onInput={(event) => {}} />
						</div>
					</div>

					<div className="form-action">
						{props.transaction != null && (
							<Link
								to={'/transactions/:id/chargeback/review'}
								className="btn-submit"
								onClick={(e) => onSubmit(e, formData)}
								state={GetReviewState(props.transaction!, formData)}
							>
								Continue
							</Link>
						)}
						{/*<Link to={"/card-management/new-card-review"} className="btn-submit" state={GetReviewState(formData)}>Continue</Link>*/}
					</div>
				</form>
			</div>
		</>
	)
}

function GetReviewState(transaction: TransactionData, formData: FormData): ChargebackReviewDTO | null {
	console.log('FORM DATA', formData)

	return {
		cardId: transaction.cardId,
		amount: transaction.amountOriginalTransaction,
		currency: transaction.currencyOriginalTransaction,
		fullName: formData.fullName!,
		reason: formData.reason?.value || '',
		transactionDate: new Date(transaction.dateCreatedUtc),
		additionalData: formData.additionalData,
		countryCode: formData.phoneCountryCode!,
		phoneNationalNumber: formData.phoneNumber!,
		phoneCode: formData.phoneCode!,
	}
}

interface ValidatedInputProps extends HTMLProps<HTMLInputElement> {
	hint?: string
	validate: (value: string | undefined) => boolean
}

function ValidatedInput(props: ValidatedInputProps) {
	const [isValid, setIsValid] = useState<boolean>(true)

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let targetValue = e.target.value
		setIsValid(props.validate(targetValue))

		if (props.onChange) {
			props.onChange(e)
		}
	}

	const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		let targetValue = e.target.value
		setIsValid(props.validate(targetValue))

		if (props.onInput) {
			props.onInput(e)
		}
	}

	const onChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
		let targetValue = e.target.value

		if (targetValue === '') {
			targetValue = '0'
		}

		setIsValid(props.validate(targetValue))

		if (props.onChange) {
			props.onChange(e)
		}
	}

	const onInputNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
		let targetValue = e.target.value

		if (targetValue === '') {
			targetValue = '0'
		}

		targetValue = parseInt(targetValue).toString()

		setIsValid(props.validate(targetValue))

		if (props.onInput) {
			props.onInput(e)
		}
	}

	const cleanProps = { ...props, validate: undefined, hint: undefined }
	return (
		<>
			<input
				{...cleanProps}
				className={props.className + (isValid ? ' invalid' : '')}
				value={props.value}
				onInput={props.type === 'number' ? onInputNumber : onInput}
				onChange={props.type === 'number' ? onChangeNumber : onChange}
			/>
			{!isValid && props.hint && <div className="invalid-hint">{props.hint}</div>}
		</>
	)
	//return <input type={props.type} placeholder={props.placeholder} className="form-control"/>
}

function ChargebackPage() {
	const routerParams = useParams()
	const transactionId = routerParams['id']

	if (!transactionId) {
		redirect('/404')
	}

	const [transaction, setTransaction] = useState<TransactionData>()

	useMemo(() => {
		//Get the transaction info
		API.Cards.GetTransactions({ Id: transactionId }).then((r) => {
			if (r.success && r.data!.data?.length > 0) {
				setTransaction(r.data!.data[0])
			} else {
				redirect('/404')
			}
		})
	}, [transactionId])

	return (
		<>
			<PageHeader title={'Charge back transaction'} />
			<BasePage>
				<Form transaction={transaction} />
			</BasePage>
		</>
	)
}

export default ChargebackPage
