import React, { ChangeEvent, useState } from 'react'
import { CardLimit, CardLimits } from 'api/Models'
import { Modal, ModalProps } from './modals/Modal'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { LimitType } from '../../enums'
// import { Input } from 'react-select/animated'
// import { ModalBodyProps } from 'react-bootstrap/ModalBody'

// interface CardLimitFormGroupProps {
// 	label: string
// 	cardLimit: CardLimit
// 	onChange: (cardLimits: CardLimits) => void
// }

interface SingleProps {
	limit: CardLimit
	title: string
	onInput: (limit: CardLimit) => void
}

function Single(props: SingleProps) {
	const cardLimit = props.limit

	const float = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target?.value) {
			return parseFloat(e.target.value)
		}

		return 0
	}

	const int = (e: ChangeEvent<HTMLInputElement>) => {
		console.log('this is int shit')
		if (e.target?.value) {
			const intValue = parseInt(e.target.value)
			e.target.value = intValue.toString()
			return intValue
		}

		return 0
	}

	return (
		<Form className="">
			<h2>{props.title}</h2>
			<hr></hr>
			<Card border={'light'}>
				<h3>Cash</h3>
				<Row className={'form-group'}>
					<Col xs={6}>
						<label>Amount</label>
						<input
							type="number"
							value={cardLimit.cashAmount}
							className="form-control"
							onChange={(e) => props.onInput({ ...props.limit, cashAmount: float(e) })}
						/>
					</Col>
					<Col xs={6}>
						<label>Use counter</label>
						<input
							type="number"
							value={cardLimit.atmUseCounter}
							className="form-control"
							onChange={(e) => props.onInput({ ...props.limit, atmUseCounter: int(e) })}
						/>
					</Col>
				</Row>

				<h3>POS</h3>
				<Row className={'form-group'}>
					<Col xs={6}>
						<label>Amount</label>
						<input
							type="number"
							value={cardLimit.nonCacheAmount}
							className="form-control"
							onChange={(e) => props.onInput({ ...props.limit, nonCacheAmount: float(e) })}
						/>
					</Col>
					<Col xs={6}>
						<label>Use counter</label>
						<input
							type="number"
							value={cardLimit.posUseCounter}
							className="form-control"
							onChange={(e) => props.onInput({ ...props.limit, posUseCounter: int(e) })}
						/>
					</Col>
				</Row>

				<h3>Internet</h3>
				<Row className={'form-group'}>
					<Col xs={6}>
						<label>Amount</label>
						<input
							type="number"
							value={cardLimit.internetAmount}
							className="form-control"
							onChange={(e) => props.onInput({ ...props.limit, internetAmount: float(e) })}
						/>
					</Col>
					<Col xs={6}>
						<label>Use counter</label>
						<input
							type="number"
							value={cardLimit.internetUseCounter}
							className="form-control"
							onChange={(e) => props.onInput({ ...props.limit, internetUseCounter: int(e) })}
						/>
					</Col>
				</Row>

				<h3>MOTO</h3>
				<Row className={'form-group'}>
					<Col xs={6}>
						<label>Amount</label>
						<input
							type="number"
							value={cardLimit.motoAmount}
							className="form-control"
							onChange={(e) => props.onInput({ ...props.limit, motoAmount: float(e) })}
						/>
					</Col>
					<Col xs={6}>
						<label>Use counter</label>
						<input
							type="number"
							value={cardLimit.motoUseCounter}
							className="form-control"
							onChange={(e) => props.onInput({ ...props.limit, motoUseCounter: int(e) })}
						/>
					</Col>
				</Row>

				<h3>Total amount</h3>
				<Row className={'form-group'}>
					<Col>
						<input
							type="number"
							value={cardLimit.totalAmount}
							className="form-control"
							onChange={(e) => props.onInput({ ...props.limit, totalAmount: float(e) })}
						/>
					</Col>
				</Row>
			</Card>
		</Form>
	)
}

interface CardLimitsFormProps {
	initial: CardLimits
	initialTab?: LimitType
	onCancel: () => void
	onSubmit: (cardLimits: CardLimits) => void
}

function CardLimitsForm(props: CardLimitsFormProps) {
	const [cardLimits, setCardLimits] = useState<CardLimits>(props.initial)
	const [tab, setTab] = useState(props.initialTab === undefined ? LimitType.Daily : props.initialTab)

	const changeCardLimit = (cardLimit: CardLimit) => {
		console.log(cardLimit)
		switch (tab) {
			case 0:
				setCardLimits({
					...cardLimits,
					daily: cardLimit,
				})
				break
			case 1:
				setCardLimits({
					...cardLimits,
					weekly: cardLimit,
				})
				break
			case 2:
				setCardLimits({
					...cardLimits,
					monthly: cardLimit,
				})
				break
		}
	}

	const getLimit = () => {
		switch (tab) {
			case LimitType.Daily:
				return cardLimits.daily
			case LimitType.Weekly:
				return cardLimits.weekly
			default:
				return cardLimits.monthly
		}
	}

	const getTitle = () => {
		switch (tab) {
			case LimitType.Daily:
				return 'Daily'
			case LimitType.Weekly:
				return 'Weekly'
			default:
				return 'Monthly'
		}
	}

	const getClass = (tabCheck: number): string => {
		if (tab === tabCheck) {
			return 'tab-button tab-btn active'
		}

		return 'tab-button tab-btn'
	}

	return (
		<form>
			<div className="form-block">
				<Row>
					<Col xs={4}>
						<Button variant={'outline-primary'} className={getClass(0)} onClick={() => setTab(0)}>
							Daily
						</Button>
					</Col>
					<Col xs={4}>
						<Button variant={'outline-primary'} className={getClass(1)} onClick={() => setTab(1)}>
							Weekly
						</Button>
					</Col>
					<Col xs={4}>
						<Button variant={'outline-primary'} className={getClass(2)} onClick={() => setTab(2)}>
							Monthly
						</Button>
					</Col>
				</Row>
				<br></br>
				<Single limit={getLimit()} title={getTitle()} onInput={changeCardLimit} />
				<Row className={'form-group'}>
					<Col xs={6}>
						<Button variant={"info"} onClick={() => props.onCancel() } >Cancel</Button>
					</Col>
					<Col xs={6}>
						<Button variant={"primary"} onClick={() => props.onSubmit(cardLimits)}>Submit</Button>
					</Col>
				</Row>
			</div>
		</form>
	)
}

//export default CardLimitsForm
function CardLimitModalBody(props: ModalProps<CardLimits>, cardLimits: CardLimits, initialTab?: LimitType) {

	const onApply = (cardLimits: CardLimits) => {
		if(props.onApply) {
			props.onApply(cardLimits)
		}
	}

	const onCancel = () => {
		if(props.onCloseModal) {
			props.onCloseModal()
		}
	}

	return (
		<>
			<CardLimitsForm initial={cardLimits} initialTab={initialTab} onSubmit={onApply} onCancel={onCancel}/>
		</>
	)
}

interface CardLimitsModalProps {
	modalProps: ModalProps<CardLimits>
	originalLimits: CardLimits
	initialTab?: LimitType
}

function CardLimitsModal(props: CardLimitsModalProps) {
	//const [cardLimits, setCardLimits] = useState<CardLimits>(props.initial);
	return (
		<Modal
			{...props.modalProps}
			modalBody={(p) => CardLimitModalBody(p, props.originalLimits, props.initialTab)}
			show={props.modalProps.show}
			style={{ zIndex: 400 }}
		/>
	)
}

export default CardLimitsModal
