export const dashboardRoute = '/dashboard'
export const transactionsRoute = '/transactions'
export const cardManagementRoute = '/card-management'
export const newCardRoute = '/card-management/new-card'
export const reviewCardRoute = '/card-management/review-card'
export const userManagementRoute = '/user-management'
export const newUserRoute = '/user-management/new-user'
export const accountStatementsRoute = '/account-statements'
export const spendingAnalysisRoute = '/spending-analysis'
export const businessProfileRoute = '/business-profile'

export const routeCardDetails = (cardId: string) => `/card-management/${cardId}`
export const transactionDetails = (transactionId: string) => `${transactionsRoute}/${transactionId}`
export const transactionChargeback = (transactionId: string) => `${transactionsRoute}/${transactionId}/chargeback`
