import { Navigate, Route, Routes } from 'react-router-dom'
import DashboardPage from './pages/DashboardPage'
import CardManagementPage from './pages/CardManagementPage'
import TransactionHistoryPage from './pages/TransactionHistoryPage'
import UserAccessPage from './pages/UserAccessPage'
import AccountStatementsPage from './pages/AccountStatementsPage'
import SpendingAnalysisPage from './pages/SpendingAnalysisPage'
import BusinessProfilePage from './pages/BusinessProfilePage'
import NotFoundPage from './pages/NotFoundPage'
import NewCardPage from './pages/NewCardPage'
import CardPage from './pages/CardPage'
import NewCardReviewPage from './pages/NewCardReviewPage'
import UserManagementPage from './pages/UserManagementPage'
import TransactionPage from './pages/TransactionPage'
import ChargebackPage from "./pages/ChargebackPage";

export default function ContentRouter() {
	return (
		<main className="page">
			<div className="page-content">
				<Routes>
					<Route path="*" element={<NotFoundPage />} />
					<Route path="/" element={<Navigate to="dashboard" />} />
					<Route path="dashboard/*" element={<DashboardPage />} />
					<Route path="transactions/" element={<TransactionHistoryPage />} />
					<Route path="transactions/:id" element={<TransactionPage />} />
					<Route path="transactions/:id/chargeback" element={<ChargebackPage />} />
					<Route path="card-management/" element={<CardManagementPage />} />
					<Route path="card-management/:id" element={<CardPage />} />
					<Route path="card-management/new-card" element={<NewCardPage />} />
					<Route path="card-management/new-card-review" element={<NewCardReviewPage />} />
					<Route path="user-access/*" element={<UserAccessPage />} />
					<Route path="user-management/" element={<UserManagementPage />} />
					<Route path="account-statements/*" element={<AccountStatementsPage />} />
					<Route path="spending-analysis/*" element={<SpendingAnalysisPage />} />
					<Route path="business-profile/*" element={<BusinessProfilePage />} />
				</Routes>
			</div>
		</main>
	)
}
