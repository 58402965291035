import PageHeader from 'components/elements/PageHeader'
import BasePage from 'components/pages/BasePage'
import React from 'react'
import UserTable from 'components/elements/UserTable'
import { Col } from 'react-bootstrap'
import LinkButton from 'components/elements/LinkButton'
import { newUserRoute } from 'Routes'
import iconPlus from "../../assets/imgs/icons/credit-card-plus.svg";

function HeaderControls() {
	return (
		<>
			<Col className="me-auto"></Col>
			<Col>
				<div className="page-nav">
					<LinkButton to={newUserRoute} icon={iconPlus} label="New User" type="primary" />
				</div>
			</Col>
		</>
	)
}

function UserManagementPage() {
	return (
		<>
			<PageHeader title={'User Access and Controls '} controls={<HeaderControls/>}/>
			<BasePage>
				<UserTable />
			</BasePage>
		</>
	)
}

export default UserManagementPage
