import API, { TransactionFilter } from 'api/API'
import React, { useEffect, useState } from 'react'
import { TransactionRow } from 'api/Models'
import { getTransactionColor, mapTransactionRows, splitCardPan } from '../../utils'
import Table, { CurrencyCell, DateTimeCell, TagCell, TextCell, VendorCell } from './Table'
import ExtendingButton from './ExtendingButton'
import iconInfo from 'assets/imgs/icons/transaction-info.svg'
import iconChargeBack from 'assets/imgs/icons/transaction-chargeback.svg'
import { transactionChargeback, transactionDetails } from '../../Routes'

interface BodyTableProps {
	filters: TransactionFilter
	pageSize?: number
	noFetch?: boolean
}

function TransactionTable(props: BodyTableProps) {
	const tableColumns = ['Merchant', 'Date and Time', 'Card', 'Amount', 'Status', 'Details']

	const [transactions, setTransactions] = useState<TransactionRow[]>([]) //TransactionRow[])
	const [page, setPage] = useState(1)
	const [totalPageCount, setTotalPageCount] = useState(0)
	const [loading, setLoading] = useState(false)
	const pageSize = props.pageSize ?? 10

	useEffect(() => {
		setLoading(true)

		if (props.noFetch) {
			return
		}

		const skip = (page - 1) * pageSize
		const take = pageSize

		API.Cards.GetTransactions({ ...props.filters, 'OffsetPaging.Skip': skip, 'OffsetPaging.Take': take })
			.then((result) => {
				if (result.success && result.data) {
					setTotalPageCount(Math.ceil(result.data.totalCount / result.data.pageSize))
					setTransactions(mapTransactionRows(result.data.data))
				}
			})
			.finally(() => setLoading(false))
	}, [props, page, pageSize])

	const rowRenderer = (row: TransactionRow) => {
		return [
			<VendorCell name={row.merchant} transactionType={row.transactionType} />,
			<DateTimeCell date={row.date} />,
			<TextCell text={splitCardPan(row.card)} className="transaction-card" />,
			<CurrencyCell amount={parseFloat(row.amount)} currency={row.currency} />,
			<TagCell text={row.status} color={getTransactionColor(row.status)} />,
			<ExtendingButton
				to={transactionDetails(row.id)}
				state={{ prev: window.location.pathname }}
				width={'90'}
				icon={iconInfo}
				label="Details"
				type="outline-primary"
			/>,
			<ExtendingButton
				to={transactionChargeback(row.id)}
				width={'125'}
				icon={iconChargeBack}
				label="Chargeback"
				type="outline-primary"
			/>,
		]
	}

	return (
		<Table
			columns={tableColumns}
			loading={loading}
			page={page}
			totalPageCount={totalPageCount}
			onPageChange={setPage}
			data={transactions}
			rowRenderer={rowRenderer}
		/>
	)
}

export default TransactionTable
