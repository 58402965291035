import React, {MouseEventHandler, useEffect, useState} from "react";

import {Modal, ModalProps} from "components/elements/modals/Modal";
import {Col, Row} from "react-bootstrap";
import {defaultToastOptions, formatCurrency} from "utils";
import {RiseLoader} from "react-spinners";
import API from "api/API";
import {CardData} from "api/Models";
import {toast} from "react-toastify";
import {iconClose, iconSearch} from "assets/assetLoader";
import {CircleSpinnerOverlay} from "react-spinner-overlay";

interface CardViewProps {
    selected?: boolean
    inactive?: boolean
    cardData: CardData
    onClick?: MouseEventHandler<HTMLDivElement>
}

function CardView(props: CardViewProps) {
    const className = "cd cd-01" + (props.selected ? " selected" : "") + (props.inactive ? " inactive" : "")
    return <div className={className} onClick={props.onClick}>
        <div className="card-status active">{props.cardData.status}</div>
        <div className="card-balance">{formatCurrency(props.cardData.balance ?? 0, props.cardData.currency)}</div>
        <div className="card-number">{props.cardData.processorData?.obscuredPan}</div>
        <div className="card-name">{props.cardData.cardholderName}</div>
    </div>
}

function FreezeCardsInternal(props: ModalProps<any>) {
    const [loading, setLoading] = useState(true)
    const [busy, setBusy] = useState(false)
    const [search, setSearch] = useState("")
    const [cards, setCards] = useState<CardData[]>([])
    const [selected, setSelected] = useState<CardData[]>([])

    useEffect(() => {
        // GetCardsHere
        setLoading(true)
        API.Cards.GetCardsWithBalance()
            .then((response) => {
                if (response.success) {
                    setCards(response.data!.data as CardData[])
                } else {
                    toast.error("Failed to load cards", defaultToastOptions())
                }
            })
            .finally(() => setLoading(false))
    }, [])


    const onSubmit = () => {
        setBusy(true)
        let counter = 1;

        async function freezeCards(selectedCards: CardData[]) {
            if (selectedCards.length === 0) return;

            for (let i = 0; i < selectedCards.length; i++) {
                if (selectedCards[i].id) {
                    const prom = API.Cards.Freeze(selectedCards[i].id as string)
                    
                    await toast.promise(
                        prom,
                        {
                            pending: `Freezing ${counter}/${selectedCards.length}`,
                            success: `Card ${selectedCards[i].processorData?.obscuredPan
                                ?? selectedCards[i].cardholderName} frozen`,
                            error: `Failed to freeze card ${selectedCards[i].processorData?.obscuredPan
                                ?? selectedCards[i].cardholderName}`
                        }
                    )
                }
                
                counter++
            }
        }

        freezeCards(selected)
            .finally(() => {
                setBusy(false)
                props.onCloseModal?.()
            })
    }

    const filter = (card: CardData) => {
        if (!search) return true;
        const pan = card.processorData?.obscuredPan?.toLowerCase() ?? "";
        const name = card.cardholderName?.toLowerCase() ?? "";
        const s = search.toLowerCase();
        return pan.includes(s) || name.includes(s)
    }

    return <>
        <div className="modal-card-freeze">
            <div className="container-fluid">
                <Row>
                    <Col xs={6}>
                        <div className="selector-search">
                            <div className="form-control-icn">
                                <img src={iconSearch} alt=""/>
                                <input type="search"
                                       className="form-control"
                                       placeholder="Search"
                                       value={search}
                                       onChange={(e) => setSearch(e.target.value)}/>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <button className="selector-close" onClick={props?.onCloseModal}>
                            <img src={iconClose} alt=""/>
                        </button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="selector-body">
                            {loading && <RiseLoader size={15}/>}
                            <Row style={(loading ? {filter: "blur(5px)"} : {})}>
                                {
                                    cards.filter(filter).map((c) =>
                                        <Col xs={6}>
                                            <CardView cardData={c}
                                                      selected={selected.includes(c)}
                                                      inactive={selected.length > 0 && !selected.includes(c)}
                                                      onClick={() => {
                                                          if (selected.includes(c)) {
                                                              setSelected(selected.filter((s) => s !== c))
                                                          } else {
                                                              setSelected(selected.concat(c))
                                                          }
                                                      }}/>
                                        </Col>
                                    )
                                }
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Col xs={12}>
                    <div className="selector-foot">
                        <button className="btn-submit inactive button-submit" disabled={selected.length === 0} onClick={onSubmit}>
                            Freeze selected card
                        </button>
                    </div>
                </Col>
            </div>
        </div>
        {busy && <CircleSpinnerOverlay loading={true}/>}
    </>
}

function FreezeCardsModal(props: ModalProps<any>) {
    return (
        <Modal {...props} modalBody={FreezeCardsInternal} style={{zIndex: 400}}/>
    )
}

export default FreezeCardsModal;
