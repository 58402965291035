import React, { PropsWithChildren } from 'react'
import { Container, Row } from 'react-bootstrap'

export interface BasePageProps extends PropsWithChildren<any> {
	className?: string
}

export function BasePage(props: BasePageProps) {
	const className = props.className ? props.className : 'page-holder transactions'

	return (
		<>
			<section className={className}>
				<Container fluid={true}>
					<Row>{props.children}</Row>
				</Container>
			</section>
		</>
	)
}

export default BasePage
