export interface NotFoundPageProps {}

function NotFoundPage() {
	return (
		<div className="content-page">
			<h2>Nothing here</h2>
		</div>
	)
}

export default NotFoundPage
